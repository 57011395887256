<template>
    <div v-if="!isLoadingBil">
        <section class="fade-in">
            <div class="aa-contenedor-basico-portada">
                <div class="portada info-portada" >
                </div>
                
            </div>

            <div class="text-start px-1 text-wrap" v-html="bilingue.informacion"></div>

        </section>
    </div>
    <div v-else>
        <PrensaSk />
    </div>
</template>

<script>

import useBilin from '../composables/useBilin'
import PrensaSk from '../components/skeleton/PrensaSk.vue'


export default {
    components: {
    PrensaSk
},
    setup() {
        const { isLoadingBil, loaded, bilingue, getBasico } = useBilin()
        getBasico()
        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.aa-bilingue
    $root:&
    
    display: flex
    flex-direction: column
    flex-wrap: wrap
    margin-top: 96px
    &-portada
        display: flex
        flex-direction: column
        .portada-bilingue
            order:1
            width: 100%
            margin-bottom: 0px

    .contenedor
        .img-contenedor
            width: 100%
            height: 118px
            img
                width: 100%
                object-fit: cover
                height: 100%
        h4
            width: 100%
            margin-block-start: 0.3rem

</style>